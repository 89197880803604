<template>
  <v-btn
    v-bind="attrs"
    :key="icon"
    ref="button"
    role="button"
    :text="['secondary', 'tertiary'].includes(variant)"
    :fab="iconOnly"
    :rounded="_.some([_.has($attrs, 'rounded'), iconOnly, memberLink === 'mini'])"
    :small="_.some([xs, sm])"
    :large="_.some([lg, xl, xxl])"
    :dark="light"
    :active="active"
    :loading="loading"
    :class="[`cy-btn cy-btn__theme--${theme} cy-btn__variant--${variant}`, {
      [`cy-btn__size--${size}`]: size !== 'normal',
      'pa-0': _.some([iconOnly, memberLink === 'mini']),
      'cy-btn--icon': iconOnly,
      'cy-btn--icon-append': iconAppend,
      'cy-btn--invisible': !visible,
      'cy-btn--member-link': memberLink,
      'cy-btn--member-link-mini': memberLink === 'mini',
      'cy-btn--active': active,
      'cy-btn--disabled': attrs.disabled,
      'cy-btn--inline-loader': loadingText,
    }]"
    @click="click"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)">
    <template
      v-if="loadingText"
      #loader>
      <v-progress-circular indeterminate/>
      <span>
        {{ loadingText }}
      </span>
    </template>
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <slot v-if="!iconOnly"/>
  </v-btn>
</template>

<script>
export const themes = ['primary', 'secondary', 'error', 'warning', 'success', 'accent', 'grey', 'paused']
export const variants = ['primary', 'secondary', 'tertiary']
export const sizes = ['xs', 'sm', 'lg', 'xl', 'xxl']
export const memberLinkVariants = ['mini']

function getProps (propNames, type, Default) {
  return _.fromPairs(propNames.map((propName) => [propName, { type, default: Default }]))
}

/**
 * Button component
 *
 * Optional
 * --------
 * @prop {String}           [theme='secondary']   Theme/main colouring of the button
 *                                                  ! must be 1 of: ['primary', 'secondary', 'error', 'warning', 'success', 'accent', 'grey', 'paused']
 * @prop {String}           [variant='primary']   Variant of the button
 *                                                  ! must be 1 of: ['primary', 'secondary', 'tertiary']
 *                                                  - **primary**   filled
 *                                                  - **secondary** outline
 *                                                  - **tertiary*  transparent/translucent
 *
 * You should only declare ONE/none of the following size props:
 * - by default the size will be 'normal' and thus no size modifications to it
 * @prop {Boolean}          [xs=false]            Button size
 * @prop {Boolean}          [sm=false]            Button size
 * @prop {Boolean}          [lg=false]            Button size
 * @prop {Boolean}          [xl=false]            Button size
 * @prop {Boolean}          [xxl=false]           Button size // ! only will apply for iconOnly buttons
 *
 * @prop {Boolean}          [light=false]         Used for buttons on non-white backgrounds (like Vuetify's dark prop)
 * @prop {String}           [icon='']             Button icon // ! must be a font-awesome or material icon class
 * @prop {Boolean}          [iconOnly=false]      No text, just the icon as a button
 * @prop {Boolean}          [visible=true]        When set to false, sets the visibility of the button to hidden
 *                                                So it will take up space in the DOM but not be visible
 * @prop {Boolean|String}   [memberLink=false]    Used to apply styles specific to the "member link" variant
 *
 * @link Figma Buttons: https://www.figma.com/file/32bzXrc5Bj6IOGnrdxF7WL/youdeploy-Master-%F0%9F%91%91?node-id=83%3A1911
 */
export default {
  name: 'CyButton',
  props: {
    theme: {
      type: String,
      validator: (theme) => themes.includes(theme),
      default: 'secondary',
    },
    variant: {
      type: String,
      validator: (variant) => variants.includes(variant),
      default: 'primary',
    },
    ...getProps(sizes, Boolean, false),
    light: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    iconPrepend: {
      type: Boolean,
      default: false,
    },
    iconAppend: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    memberLink: {
      type: [Boolean, String],
      validator: (variant) => {
        return typeof variant === 'boolean' ||
          (typeof variant === 'string' && memberLinkVariants.includes(variant))
      },
      default: false,
    },
  },
  computed: {
    attrs () {
      const { color, ...attrs } = this.$attrs
      return attrs
    },
    size () {
      const size = sizes.find((size) => this[size])
      return _.isEmpty(size) ? 'normal' : size
    },
  },
  mounted () {
    const tooManySizesDeclared = _.filter(sizes.map((size) => this[size])).length > 1
    if (tooManySizesDeclared) {
      const declaredSizes = _.fromPairs(sizes.map((size) => [size, this[size]]))
      console.error(`[CyButton] too many size props have been declared, you may use only 1 or none`, declaredSizes, this.$el)
    }
    const hasColorAttr = _.has(this.$attrs, 'color')
    if (hasColorAttr) {
      const { theme, $attrs: { color } } = this
      console.warn(`[CyButton] "color" prop found, please remove this. Use "theme" instead.`, { color, theme, parent: this.$parent.$options.name })
    }
  },
  methods: {
    click ($event) {
      this.$emit('click', $event)
      this.$refs?.button?.$el?.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-btn.v-btn.cy-btn {
  min-width: auto;
  height: 36px;
  padding: 0 16px;
  font-size: 14px;
  font-weight: $font-weight-default;
  line-height: 16px;

  .v-icon { font-size: 18px; }
  svg.v-icon { height: 15px; }

  ::v-deep {
    .v-progress-circular {
      width: 38% !important;
      height: 38% !important;
    }
  }

  &--inline-loader {
    ::v-deep {
      .v-progress-circular {
        width: 32px !important;
        height: 38% !important;
      }
    }
  }

  &[to]::before {
    opacity: 0;
  }

  + .cy-btn:not(:first-child, .cy-btn--member-link-mini) {
    margin-left: 8px;
  }

  @each $theme in map.keys($button-theme-variants) {
    @each $variant, $config in map.get($button-theme-variants, $theme) {
      &__theme--#{$theme}.cy-btn__variant--#{$variant} {
        background: map.get($config, "background");
        color: map.get($config, "color");

        @if $variant == "secondary" {
          border: 1px solid currentColor !important;
        }

        @if $variant == "tertiary" {
          font-weight: $font-weight-bolder;
        }

        &:hover {
          background: map.get($config, "hover-background");
        }

        &:active,
        &:focus {
          background: map.get($config, "active-background");
        }
      }
    }
  }

  &__size {
    $icon-safe-zone: calc(20 / 24); /* accounts for the icon "safe zone" (absent in svg) */

    @each $size, $config in deep-map-get($button-sizes, "text") {
      &--#{$size} {
        height: map.get($config, "height");
        padding: map.get($config, "padding");
        font-size: map.get($config, "font-size");
        line-height: map.get($config, "line-height");

        .v-icon { font-size: map.get($config, "icon-size"); }
        svg.v-icon { height: calc(#{map.get($config, "icon-size")} * #{$icon-safe-zone}); }
      }
    }
  }

  &--icon {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;

    .v-icon {
      font-size: $font-size-h3;
    }

    svg.v-icon {
      height: 20px;
    }

    &.cy-btn__variant--primary {
      box-shadow: 0 1px 1px #{cy-get-color("grey", "dark-2", 0.5)};

      &:active,
      &:focus {
        box-shadow:
          0 5px 22px #{cy-get-color("primary", "dark-1", 0.12)},
          0 12px 17px #{cy-get-color("primary", "dark-1", 0.14)},
          0 7px 8px #{cy-get-color("primary", "dark-1", 0.2)};
      }
    }

    &.cy-btn__size {
      @each $size, $config in deep-map-get($button-sizes, "icon-only") {
        &--#{$size} {
          width: map.get($config, "btn-size");
          height: map.get($config, "btn-size");

          .v-icon { font-size: map.get($config, "icon-size"); }
        }
      }
    }
  }

  &--disabled {
    ::v-deep .v-icon,
    ::v-deep .v-btn__content {
      color: cy-get-color("grey", "dark-1") !important;
    }
  }

  &--icon-append {
    ::v-deep .v-btn__content {
      flex-direction: row-reverse;

      .v-icon {
        margin-left: 4px !important;
      }
    }
  }

  &--invisible {
    visibility: hidden;
  }

  &:active,
  &--active &:focus {
    &::before {
      background: transparent;
    }
  }

  &:not(.cy-btn--icon) ::v-deep {
    .v-icon {
      margin: 0 0.1em 0 -0.2em;

      &.material-icons:not(.reassign-owner__icon) {
        display: inline-block;
        width: 1.1em;
      }
    }
  }

  &.cy-btn--member-link {
    &.cy-btn__size--sm {
      height: 36px;
    }

    &-mini {
      height: 24px;
      background: none;
    }

    &.cy-btn--disabled {
      padding: 0;
    }

    ::v-deep .cy-member__title {
      line-height: 17px;
    }
  }
}
</style>
